














































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { BOOKING_PORTAL_URL } from '@/config'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  headers = []
  routes = {}
  rowActions = []
  topActions = []
  embedItem = null
  embedModal = false

  mounted() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.rowActions = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: 'Settings/Booking/Portals/Edit',
            params: { calendarId: this.$route.params.calendarId, id: item.id },
          }
        },
      },
      {
        id: 'delete',
      },
      {
        id: 'link',
        absolute: true,
        popup: true,
        route: (item) => {
          return this.getPortalUrl(item)
        },
      },
      {
        id: 'embed',
        click: (_, item) => {
          this.embedItem = item
          this.embedModal = true
        },
      },
    ]
    this.topActions = [
      {
        id: 'new',
        route: () => {
          return {
            name: 'Settings/Booking/Portals/New',
            params: { calendarId: this.$route.params.calendarId },
          }
        },
      },
    ]
  }

  private get url(): string {
    return '/v4/site/calendars/' + this.$route.params.calendarId + '/portals'
  }

  private getPortalUrl(item): string {
    return BOOKING_PORTAL_URL + '/' + item.id
  }

  private get embedCode(): string {
    if (!this.embedItem) {
      return ''
    }
    const indent = '    '
    const lines = [
      '<iframe',
      indent + 'src="' + this.getPortalUrl(this.embedItem) + '"',
      indent + 'border="0"',
      indent + 'style="width: 100%; height: 1000px; border: 0;"',
      '>',
      '</iframe>',
    ]
    return lines.join('\n')
  }

  private copyEmbedCode(): void {
    const elem = document.getElementById('embedTextArea') as HTMLTextAreaElement
    if (elem.setSelectionRange) {
      elem.setSelectionRange(0, 99999)
    }
    if (elem.select) {
      elem.select()
    }
    document.execCommand('copy')
  }
}
